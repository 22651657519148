import React from 'react';
import classes from './Plans.module.scss';
import Page from "../../components/Page/Page";
import { useQuery } from "@apollo/client";
import { FETCH_PLANS } from "../../graphql";
import { Table, Td, Tr } from "../../components/Table/Table";

const Plans = () => {
    const { loading, data: { getPlans: plans } = {} } = useQuery(FETCH_PLANS, {
        variables: { filter: { search: "" } },
    });

    console.log(plans)

    return (
        <Page loading={loading}>
            <div className={classes.TableRTX}>
                <h2 className="title">Для приемников с поддержкой RTX</h2>
                <Table value={[
                    {name: "name", label: 'Артикул'},
                    {name: "time", label: 'Наименование'},
                    {name: "manufacturer", label: 'Производители'},
                    {name: "station", label: 'Цена в тенге'}
                ]}>
                    <Tr>
                        <Td>95690-20</Td>
                        <Td>Услуги по подписке Geospatial Continental CenterPoint RTX - 1 год</Td>
                        <Td>Trimble, Spectra Geospatial, GEOKURS CHCNAV</Td>
                        <Td>Цену уточняйте у менеджеров</Td>
                    </Tr>
                    <Tr>
                        <Td>95690-21</Td>
                        <Td>Услуги по подписке Geospatial Continental CenterPoint RTX - 1 год</Td>
                        <Td>Другие GNSS приёмники, поддерживающие технологию RTX</Td>
                        <Td>Цену уточняйте у менеджеров</Td>
                    </Tr>
                </Table>
            </div>
            <div className={classes.Plans}>
                <h2 className="title">Для приемников GEOKURS E-Survey, Trimble, Spectra Geospatial, SmaxGeo, GEOKURS CHCNAV</h2>
                { loading ? (
                    <h1>Загрузка...</h1>
                ) : (
                    <Table value={[
                        {name: "name", label: 'Наименование тарифа'},
                        {name: "time", label: 'Срок'},
                        {name: "station", label: 'Базовые станции'},
                        {name: "brand", label: 'Производители', type: 'brand'},
                        {name: "price", label: 'Цена без НДС'},
                    ]}>
                        { plans && plans.filter(plan => plan.device.type === 'TRIMBLE_SP').map(plan => (
                            <Tr key={plan._id} style={{ backgroundColor: plan.color }}>
                                <Td className={classes.Name}>{plan.name}</Td>
                                <Td>{plan.time.name}</Td>
                                <Td>{plan.station.name}</Td>
                                <Td>{plan.device.name}</Td>
                                <Td className={classes.Price}>{plan.price} ₸</Td>
                            </Tr>
                        ))}
                    </Table>
                )}
            </div>
            <div className={classes.Plans}>
                <h2 className="title">Для приемников любых производителей, кроме GEOKURS E-Survey, Trimble, Spectra Geospatial, SmaxGeo, GEOKURS CHCNAV</h2>
                { loading ? (
                    <h1>Загрузка...</h1>
                ) : (
                    <Table value={[
                        {name: "name", label: 'Наименование тарифа'},
                        {name: "time", label: 'Срок'},
                        {name: "station", label: 'Базовые станции'},
                        {name: "brand", label: 'Производители', type: 'brand'},
                        {name: "price", label: 'Цена без НДС'},
                    ]}>
                        { plans && plans.filter(plan => plan.device.type === 'EVERYTHING_TRIMBLE_SP').map(plan => (
                            <Tr key={plan._id} style={{ backgroundColor: plan.color }}>
                                <Td className={classes.Name}>{plan.name}</Td>
                                <Td>{plan.time.name}</Td>
                                <Td>{plan.station.name}</Td>
                                <Td>{plan.device.name}</Td>
                                <Td className={classes.Price}>{plan.price} ₸</Td>
                            </Tr>
                        )) }
                    </Table>
                )}
            </div>
            <div className={classes.TableSupport} id="support-plans">
                <h2 className="title">Техническая поддержка</h2>
                <Table value={[
                    {name: "name", label: 'Наименование'},
                    {name: "srok", label: 'Срок'},
                    {name: "price", label: 'Цена в тенге, без НДС'}
                ]}>
                    <Tr>
                        <Td>Услуга технической поддержки</Td>
                        <Td>до 1-го часа</Td>
                        <Td>20 000</Td>
                    </Tr>
                    <Tr>
                        <Td>Услуга технической поддержки</Td>
                        <Td>1 год</Td>
                        <Td>200 000</Td>
                    </Tr>
                </Table>
            </div>            
        </Page>
    )
};

export default Plans;
