import React from 'react';
import classes from './Instructions.module.scss';
import pdf from '../../assets/img/pdf.svg';
import Page from "../../components/Page/Page";
import { useQuery } from "@apollo/client";
import { FETCH_INSTRUCTIONS } from "../../graphql";


const Instructions = () => {
    const { loading, data: { getSubscriptionInstructions: instructions } = {} } = useQuery(FETCH_INSTRUCTIONS, {
        variables: { filter: { search: "" } },
    });

    return (
        <Page loading={loading}>
            <h1 className="title">Инструкции</h1>
            <div className={classes.Desc}>
                <p>
                    <b>На нашем сайте можно получить доступ:</b>
                </p>
                <ul>
                    <li>к сети станций для работы с ровером в режиме RTK через сотовую связь;</li>
                    <li>к скачиванию файлов RINEX любой версии с базовых станций для постобработки.</li>
                </ul>
                <p>
                    Подключение к базовым станциям доступно для оборудования, поддерживающего работу в режиме RTK.
                </p>
                <p>
                    Если данные инструкции не привели Вас к решению проблемы, Вы можете воспользоваться услугой платной технической поддержки.
                </p> 
                <p>
                    Для ознакомления с тарифами посетите раздел <a href="/plans/#support-plans">"Тарифы"</a> на нашем сайте.
                </p>
                <p>Заявку на платную техническую поддержку Вы можете оставить на странице: <a href="https://geokurs.kz/services/support" target="_blank">geokurs.kz/services/support</a></p>
            </div>
            <div className={classes.Pdf}>
                <h1 className="title">Файлы:</h1>
                { instructions && instructions.map(item => (
                    <a className={classes.Item} key={item._id} target="_blank" href={item.file} rel="noreferrer">
                        <img src={pdf} alt=""/>
                        <span>{item.label}</span>
                    </a>
                )) }
            </div>
        </Page>
    )
};

export default Instructions;
