import { useEffect } from 'react';
const IntellectDialogWidget = () => {
useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://chat.intellectdialog.com/files/constructors/widgets/app.js?t=${Date.now()}`;
    script.async = true;
    document.head.appendChild(script);
    const onLoad = () => { if (window.IntDial) { new window.IntDial('6fd5f9cb-4294-04ca-14ae-65ca9dca3f43'); } };
    window.addEventListener('load', onLoad);
    return () => { window.removeEventListener('load', onLoad); document.head.removeChild(script); };
}, []);
return null;
};
export default IntellectDialogWidget;