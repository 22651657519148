import React, { useState } from 'react';
import classes from './Rtx.module.scss';
import rtx from '../../assets/img/trimble-rtx.png';
import centerpoint from '../../assets/img/banner-centerpoint.png';
import Page from "../../components/Page/Page";
import arrow from "../../assets/img/arrow.svg";
import centerPointBg from "../../assets/img/center-point-bg.png";
import {Table, Td, Tr} from "../../components/Table/Table";

import BitrixForm from '../../components/BitrixScript';

import CenterPointRtx from './Tab/CenterPointRtx';
import RangePointRtx from './Tab/RangePointRtx';
import CenterPointRtk from './Tab/CenterPointRtk';
import CenterPointVrs from './Tab/CenterPointVrs';
import RangePointRtxPost from './Tab/RangePointRtxPost';

const Rtx = () => {
    const [tab, setTab] = useState('CenterPoint RTX');

    const active = (name) => {
        if (tab === name) {
            return classes.BlockTarifTabsButton + ' ' +classes.BlockTarifTabsActive;
        } else {
            return classes.BlockTarifTabsButton;
        }
    }

    return (
        <Page loading={false}>
            <div className={classes.Rtx}>
                <h2 className="title">Поправки Omnistar/RTX</h2>
                <div className={classes.BlockOmnistar}>
                    <div>
                        <img src={rtx} alt=""/>
                    </div>
                    <div>
                        <h3>Поправки Omnistar/RTX</h3>
                        <p>
                            Дифференциальный сервис Trimble RTX позволяет работать с точностью RTK в любой точке мира используя только один приемник.
                        </p>
                        <p>
                            Для получения высокой точности не требуется радио сигнал, GSM/GPRS сигнал и стационарная базовая станция. Получение поправок осуществляется напрямую от спутников.
                        </p>
                        <p>
                            Дифференциальный сервис Trimble RTX использует данные опорных станций, установленных по всему миру, для определения высокоточного позиционирования на основе информации о положении спутниковых орбит и синхронизации часов установленных на спутниках. Благодаря использованию сервиса Trimble RTX можно добиться плановой точности при измерениях меньше 4 см.
                        </p>
                    </div>
                </div>
                <div className={classes.BlockCenterPoint} style={{ backgroundImage: `url(${centerPointBg})` }}>
                    <div>
                        <img src={centerpoint} alt=""/>
                    </div>
                    <div>
                        <h2>
                            Демо-подписки <span>CenterPoint RTX на 30 дней бесплатно </span>
                            <br />всем владельцам приемников ГНСС Trimble с
                            <br />поддержкой данной технологии.
                        </h2>
                        <p>
                            Список GNSS приемников Trimble Geospatial с поддержкой технологии RTX:
                            <br />Trimble Geospatial – R780, R750, R580, R12i, R12, R10-2, R10, R2, R9s, SP85, NetR9 Geo, Alloy
                            <br />Spectra Geospatial - SP60, SP90m. SmaxGeo
                            <br />GEOKURS CHCNAV i90PRO
                        </p>
                        <a className={classes.ButtonLink} href="https://store.trimble.com/OA_HTML/tnvortx_ibeDemoSubPostProcess.jsp" target="_blank" rel="noreferrer">Оформить Демо подписку <img src={arrow} alt=""/></a>
                        <h3>промо-код активации <b>FREE30</b></h3>
                    </div>
                </div>
                <div className={classes.BlockTarifPlan}>
                    <h2>Тарифные планы</h2>
                    <Table value={[
                        {name: "name", label: 'Артикул'},
                        {name: "time", label: 'Наименование'},
                        {name: "manufacturer", label: 'Производители'},
                        {name: "station", label: 'Цена в тенге'}
                    ]}>
                        <Tr>
                            <Td>95690-20</Td>
                            <Td>Услуги по подписке Geospatial Continental CenterPoint RTX - 1 год</Td>
                            <Td>Trimble, Spectra Geospatial, SmaxGeo, GEOKURS CHCNAV</Td>
                            <Td>Цену уточняйте у менеджеров</Td>
                        </Tr>
                        <Tr>
                            <Td>95690-21</Td>
                            <Td>Услуги по подписке Geospatial Continental CenterPoint RTX - 1 год</Td>
                            <Td>Другие GNSS приёмники, поддерживающие технологию RTX</Td>
                            <Td>Цену уточняйте у менеджеров</Td>
                        </Tr>
                    </Table>
                </div>
                <div className={classes.BitrixForm}>
                    <BitrixForm className={classes.BitrixButton}>
                        Оставить заявку
                    </BitrixForm>
                </div>
                <div className={classes.BlockTarifTabs}>
                    <div className={classes.BlockTarifTabsNav}>
                        <button className={active('CenterPoint RTX')} onClick={() => setTab('CenterPoint RTX')}><span>CenterPoint RTX</span></button>
                        <button className={active('RangePoint RTX')} onClick={() => setTab('RangePoint RTX')}><span>RangePoint RTX</span></button>
                        <button className={active('CenterPoint RTK')} onClick={() => setTab('CenterPoint RTK')}><span>CenterPoint RTK</span></button>
                        <button className={active('CenterPoint VRS')} onClick={() => setTab('CenterPoint VRS')}><span>CenterPoint VRS</span></button>
                        <button className={active('CenterPoint RTX постобработка')} onClick={() => setTab('CenterPoint RTX постобработка')}><span>CenterPoint RTX постобработка</span></button>
                    </div>
                    <div className={classes.BlockTarifTabsItems}>
                        {tab === 'CenterPoint RTX' && <CenterPointRtx /> }
                        {tab === 'RangePoint RTX' && <RangePointRtx /> }
                        {tab === 'CenterPoint RTK' && <CenterPointRtk /> }
                        {tab === 'CenterPoint VRS' && <CenterPointVrs /> }
                        {tab === 'CenterPoint RTX постобработка' && <RangePointRtxPost /> }
                    </div>
                </div>
            </div>
        </Page>
    )
};

export default Rtx;
