import React from 'react';
import Link from "../../ui/Link/Link";
import logo from '../../assets/img/logo.png';
import whatsapp from '../../assets/img/whatsapp.svg';
import telegram from '../../assets/img/telegram.svg';
import classes from './Navbar.module.scss';
import DemoButton from "../UI/Button/DemoButton";
import ButtonAuth from "../UI/Button/ButtonAuth";
import { Link as RouterLink } from "react-router-dom";
import { Sling as Hamburger } from 'hamburger-react'

import policy from '../../assets/pdf/Политика_в_отношении_обработки_персональных_данных.pdf';
import offer from '../../assets/pdf/Положение_о_получении_доступа_к_базовым_GNSS_станциям.pdf';



const Navbar = ({ setOpen, isOpen }) => {
    return (
        <div className={classes.Header}>
            <div className={classes.TomMobile}>
                <Phone />
                <ButtonAuth color="white" />
            </div>
            <div className={classes.Top}>
                <div className={classes.Wrapper}>
                    <div className={classes.Call}>
                        <Phone />
                        <div className={classes.Item}>
                            <span>Мессенджеры:</span>
                            <a href="https://wa.me/77001111414?text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5!%20%D0%A3%20%D0%BC%D0%B5%D0%BD%D1%8F%20%D0%B2%D0%BE%D0%BF%D1%80%D0%BE%D1%81%20%D0%BF%D0%BE%20%D0%B1%D0%B0%D0%B7%D0%BE%D0%B2%D1%8B%D0%BC%20%D1%81%D1%82%D0%B0%D0%BD%D1%86%D0%B8%D1%8F%D0%BC!%20" className={classes.Icons} target="_blank" rel="noreferrer"><img src={whatsapp} alt=""/></a>
                            <a href="https://t.me/BSGeokurs" className={classes.Icons} target="_blank" rel="noreferrer"><img src={telegram} alt=""/></a>
                        </div>
                    </div>
                    <div className={classes.Links}>
                        <a href={policy} target="_blank" rel="noreferrer" >Политика конфиденциальности</a>
                        <a href={offer} target="_blank" rel="noreferrer" >Публичная оферта</a>
                    </div>
                </div>
            </div>
            <div className={classes.Bottom}>
                <div className={classes.Wrapper}>
                    <div className={classes.Menu}>
                        <RouterLink to="/"><img src={logo} alt=""/></RouterLink>
                        <ul>
                            <li><Link to="/stations">СПИСОК СТАНЦИЙ</Link></li>
                            <li><Link to="/instructions">ИНСТРУКЦИИ</Link></li>
                            <li><Link to="/plans">ТАРИФЫ</Link></li>
                            <li><Link strict to="/cabinet#rinex">RINEX</Link></li>
                            <li><Link to="/rtx">RTX</Link></li>
                        </ul>
                    </div>
                    <div className={classes.Controls}>
                        <ButtonAuth color="dark" />

                        <DemoButton />
                    </div>

                    <div className={classes.Burger}>
                        <Hamburger size={30} toggle={setOpen} toggled={isOpen} color="#197323"/>
                    </div>
                </div>
            </div>
        </div>
    )
};

export const Phone = () => {
    return (
        <div className={classes.Item}>
            <span className={classes.CallCenter}>Call-центр:</span>
            <a href="tel:+77272290000" className={classes.Online}>online +7 727 229 00 00</a>
        </div>
    )
}

export default Navbar;
